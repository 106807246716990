import React, { useContext } from 'react';
import { Dropdown, Avatar } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import './index.scss';
import { GlobalContext } from '@src/context/GlobalContext';

const HeaderMenu = () => {
  const { state: { userInfo} } = useContext(GlobalContext);
  const navigate = useNavigate();
  const logoutHandle = () => {
    localStorage.removeItem("access_token");
    navigate("/login");
  }

  const items: any = [
    {
      key: 'logout',
      label: <div onClick={logoutHandle}><FormattedMessage id="logout" defaultMessage="退出登录" /></div>,
    },
  ];

  return (
    <div>
      <Dropdown menu={{ items }}>
        <div className='user-name'>
          {userInfo?.username}
        </div>
      </Dropdown>
    </div>
  )
}

export default HeaderMenu;